@import '../../../../style/media-variables';

.how_to_container_row, .how_to_container_column {
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 984px;
  align-items: center;
  margin: 0 auto;
  gap: 3rem;
  padding: 10px;
  margin-top: 3rem;

  .left_side {
    width: 90%;
    @include xl-min() {
      width: 70%;
    }

    .jcms-html-text {
      span {
        font-size: 16px;
        font-weight: 400;
        font-family: var(--font-family);
      }
    }
  }

  .right_side {
    width: 30%;
    display: flex;
    justify-content: center;

    button {
      border: 1px solid #DFDFDF !important;
    }
  }
}

.how_to_container_row {
  flex-direction: row;
}

.how_to_container_column {
  flex-direction: column;
  gap: 4rem;
  padding: 0 20px;
}