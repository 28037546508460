@import './../../../style/media-variables.scss';

.thankyou-page-cta-wrapper {
    display: flex;
    height: 21rem;
    flex-direction: column-reverse;

    .jcms-cta-size-large button:hover {
        background: var(--primary) !important;
    }

    @include xxs-max() {
        height: auto;
    }
}
