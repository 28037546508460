@import "~@jnext/commons/dist/variables.scss";
@import './../../style/mixins';
@import './../../style/media-variables';

.product-detail-container {
    .ant-col {
        padding: 0 var(--static-halfmargin);
    }

    >.ant-row>.ant-col {
        padding: 0;
    }

    .product-media-container {

        .slick-track {
            max-height: toRem(350px);
        }

        .media-content {
            border-radius: 18px;
            overflow: hidden;
        }

        .product-media-thumbnail-slider {
            padding: 0;

            &.contain {
                .media-content {
                    img {
                        object-fit: contain;
                    }
                }
            }

            &.cover {
                .media-content {
                    img {
                        width: 100%;
                    }
                }
            }

            .jcms-slider.jcms-slider-orientation-vertical {
                .slick-disabled {
                    display: none !important;
                }

                .slick-prev::before,
                .slick-next::before {
                    color: var(--textPrimary) !important
                }

                .slick-arrow {
                    background-color: var(--background) !important;
                }
            }

            .media-thumbnail  {
                cursor: pointer;
                border: 1px solid var(--product-details-images-border);
                background: var(--product-details-images-background);
                // max-width: 50px;
                // -webkit-box-shadow: 0 2px 8px 0 rgba(5, 40, 80, 0.1);
                // box-shadow: 0 2px 8px 0 rgba(5, 40, 80, 0.1);
                border-radius: 18px;

                &.active {
                    border: 1px solid var(--product-details-images-border-active);
                }

                .media-content {
                    font-size: 2.5rem;
                    text-align: center;
                    color: var(--tertiary);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:before {
                        content: "";
                        display: block;
                        padding-top: 100%;
                        float: left;
                    }
                }
            }
            .media-thumbnail-PDF, .media-thumbnail-EMBED_VIDEO, .media-thumbnail-SELF_HOSTED_VIDEO  {
                .media-content {
                    .media-item{
                        width: initial;
                        max-width: 100%;
                        max-height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }

        .product-media-selected {
            width: 100%;
            height: toRem(618px);
            border-radius: 18px;
            border: 1px solid var(--product-details-images-border);
            background: var(--product-details-images-background);
            display: table;
            overflow: hidden;

            &.contain {
                img {
                    object-fit: contain;
                }
            }

            &.cover {
                img {
                    width: 100%;
                }
            }

            .jcms-image img.jcms-image-placeholder {
                object-position: center;
            }
            .media-content {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }
        }
    }

    .product-content {
        .product-purchase-disabled {
            color: var(--error);
            padding: 0 var(--static-halfmargin) !important
        }

        .brands-info {
            .brands-logo {
                display: flex;
                align-items: center;

                img.brand-logo {
                    max-width: 6rem;
                    float: left;
                    margin-right: 1rem;
                }
            }

            .brands-name {
                padding-top: var(--static-halfmargin);

                .brand-name {
                    margin-right: var(--static-halfmargin);
                }
            }
        }

        .product-info {
            >.jsectionswrapper {
                gap: var(--static-halfmargin);
            }

            .brands-info {
                width: 100%;
            }

            .product-form {
                width: 100%;
                margin-top: var(--static-halfmargin);

                >.ant-col {
                    padding-left: 0;
                    padding-right: 0;
                }

                .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                    content: none;
                }

                .actions {
                    padding: var(--static-halfmargin) var(--static-halfmargin);
                }

                .jcms-cta-size-large button:hover {
                    background: var(--primary) !important;
                }

                .form-style-wrapper .form-compositor {

                    &.form-compositor-redemption-form {
                        .ant-row.dispRow {
                            margin-bottom: 0 !important;
                        }

                        .ant-form-item-explain-error {
                            text-align: left;
                        }
                    }

                    label>div {
                        color: var(--primary)
                    }

                    .ant-form-item {
                        margin-bottom: 0;
                    }

                    .ant-row.dispRow {
                        margin-bottom: var(--static-halfmargin) !important;
                    }

                    .radio-container {
                        .price {
                            position: relative;

                            &.discounted {
                                text-decoration: line-through;
                                padding-right: toRem(5px);
                                // content: "/";
                                // font-weight: bold;
                                // position: absolute;
                                // left: 0;
                                // transform: translateX(50%);
                            }

                            &.discount {
                                font-weight: bold;
                            }
                        }

                        margin-bottom: 0;

                        .ant-col {
                            min-width: 100%;

                            .ant-radio-group {
                                float: left;

                                .ant-radio-button-wrapper {
                                    &:before {
                                        content: none;
                                    }

                                    margin-bottom: var(--static-halfmargin);
                                    border-left-width: 1.02px;
                                    margin-right: var(--static-halfmargin);
                                    border-end-start-radius: var(--input-border-radius);
                                    border-start-start-radius: var(--input-border-radius);
                                    border-end-end-radius: var(--input-border-radius);
                                    border-start-end-radius: var(--input-border-radius);
                                    height: 3rem;
                                    line-height: 3rem;
                                }
                            }

                            .ant-form-item-row {
                                .ant-col {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }

            .availability-label {
                color: var(--primary-light);
            }

            .jaccordion {
                .jaccordion-title {
                    color: var(--primary);
                }
            }

            h2.jcms-html-section-title {
                color: var(--primary);

                span {
                    font-size: var(--section-title);
                }
            }

            .categories {
                padding-top: var(--static-halfmargin);
            }

            .delivery-info {
                padding: 0 25px;
                background-color: #E6F0F8;
                display: flex;
                height: 48px;
                border-radius: 40px;
                border: 1px solid black;
                justify-content: center;
                gap: 10px;
                align-items: center;
                width: fit-content;
                margin: var(--static-halfmargin) var(--static-halfmargin);

                & .description-container {
                    color: var(--primary);
                }

                @include sm-max() {
                    margin: 0;
                }
            }

            .categories {
                .ant-typography {
                    color: var(--primary);
                }

                .category {
                    margin-right: 1rem;
                }
            }
        }
    }

    .info-box {
        margin-top: 2rem;
    }
}

@include lg-max() {

    .product-detail-container {

        .product-media-container {
            > .ant-col {
                padding: 0;
            }

            .product-media-selected {
                height: toRem(350px);
            }

            .product-media-thumbnail-slider {

                &.horizontal {
                    padding: var(--static-margin) 0 var(--static-margin) var(--static-margin);
                    box-sizing: content-box;
                    overflow: hidden;

                    .jcms-slider {
                        margin-left: calc(var(--static-margin) - var(--static-margin) * 2);
                    }
                }
            }
        }

    }
}
@include sm-max() {

    .product-detail-container {

        .ant-col {
            padding: 0;
        }

        .jaccordion {
            .ant-collapse-header {
                padding-left: 0 !important;
                padding-right: 0 !important;

            }

        }

        .product-info .product-form .actions {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }


    }
}


@include xxs-max() {
    .product-detail-container .product-media-container {

        .product-media-thumbnail-slider {

            .jcms-slider {
                margin-left: calc(var(--static-margin) - var(--static-margin) * 1.75)!important;

                .slick-list .slick-track .slick-slide {
                    margin: 0 var(--static-halfmargin);

                    .media-thumbnail  {
                        border-radius: 10px;

                        .media-content {
                            font-size: 1.5rem;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
}


.quantityForm, .availability-title{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include sm-max(){
        gap: 0.5rem;
    }

    .availability-label{
        margin-left: 0.5rem;
    }

    .buttonInput {
        background: var(--primary);
        border: none;
    }

    .ant-input-number-handler-wrap{
        display: none;
    }

    .ant-form-item{
        margin-bottom: 0!important;
    }
    .ant-form-item input[type=text]{
        text-align: center;
    }
}
.availability-title{
    margin-bottom: 0.5rem;
}


.btn-back-catalog {
  margin-bottom: toRem(20px);
  height: toRem(35px);
  width: auto;
  min-width: toRem(117px);
  border-radius: toRem(20px);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: none;

  @include xs-max() {
    box-shadow: none;
    justify-content: start;
  }
}

.btn-back-catalog span {
  font-family: var(--font-family);
  font-size: toRem(13px);
  font-weight: 600;
  line-height: toRem(19.5px);
}
