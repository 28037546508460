@import '../../../../style/media-variables';

.card-position-container {
  display: flex;
  flex-direction: row;
  gap: 1.8rem;
  justify-content: center;
  max-width: 1120px;


  @include sm-max() {
    flex-wrap: wrap;
    padding: 0;
    gap: 1.8rem;
  }

  @include sm-min() {
    gap: 0.8rem
  }

  @include md-min() {
    gap: 0.8rem
  }

  @include lg-min() {
    gap: 1.8rem
  }

  .card-position-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 1.8rem;

    @include sm-max() {

      gap: 1.8rem;
    }

    @include sm-min() {

      gap: 0.8rem
    }

    @include md-min() {

      gap: 0.8rem
    }

    @include lg-min() {
      gap: 1.8rem
    }

  }

  @include xl-min() {
    .col-first {
      align-items: end;
    }
    .col-second {
      padding: 30px 0;
      align-items: start;
    }
  }
}