@import './../../style/media-variables.scss';
@import '../../style/mixins';

.vas-card-section-container {
  @include xxl-min() {
    .jsection > .jsection-container {
      padding-left: unset;
      padding-right: unset;
      padding-bottom: toRem(100px);
    }
  }

  .vas-card-page {
    .vas-header, .vas-header_minor {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 2rem;

      .vas-header-image {
        margin-bottom: 2rem;
      }

      .vas-header-cta {
        .jcms-cta-type-button {
          background: var(--white);
          width: toRem(171px);
          height: toRem(44px);
          @include sm-max() {
            width: toRem(139px);
            height: toRem(40px);
          }

          span {
            font-family: var(--font-family);
            color: var(--textPrimary);
            font-weight: 600;
            font-size: toRem(16px);
          }

          &:hover {
            background: var(--white);

            span {
              color: var(--textPrimary);
            }
          }
        }
      }

      .info-box {
        border-radius: 25px;
        max-width: 100%;
        padding: 1rem;
        @include sm-max() {
          margin: 0 1rem;
        }
      }
    }

    .vas-header_minor {
      margin-bottom: 0;
    }

    .vas-card-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      gap: 3rem;
      @include xl-max {
        padding: 0 10px;
      }
    }

    .vas-card-container-auth {
      @extend .vas-card-container;
      @include xl-max {
        padding: 0;
      }
    }

    .vas-registration-cta {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        width: toRem(248px);
        height: toRem(44px);
        @include sm-max() {
          width: toRem(200px);
          height: toRem(44px);
        }

        span {
          font-family: var(--font-family);
          font-weight: 600;
          font-size: toRem(16px);
          line-height: toRem(24px);
        }

        &:hover {
          background: var(--primary);
        }
      }
    }

    .footer-margin {
      margin-bottom: 2rem;
    }
  }
}

.vas-card-section-container-auth {
  @extend .vas-card-section-container;

  @include xxs-max() {
    .jsection > .jsection-container {
      padding-left: unset;
      padding-right: unset;
      padding-bottom: toRem(100px);
    }
  }
}


.vas-comparison {
  width: 100%;
  margin: 0 auto;
  margin-top: 4rem;
  max-width: toRem(1120px);

  @include xl-max() {
    padding: 20px 10px;
  }

  .jsection > .jsection-container {
    padding-left: unset;
    padding-right: unset;
  }
}

.vas-comparison-auth {
  @extend .vas-comparison;
  @include xl-max() {
    padding: 20px 30px;
  }
}

.subscription-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .info-box {
    max-width: 100%;
    background: unset;
    justify-content: flex-start;
    gap: 1rem;

    .warning-img {
      color: var(--warning-color);
      width: toRem(24px);
      height: toRem(24px);
    }

    .jcms-html-text {
      span {
        font-size: toRem(14px);
        font-weight: 400;
        font-family: var(--font-family);
        color: var(--warning-color);
      }
    }

  }
}
