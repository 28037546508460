.cart-summary {
    top: var(--static-margin);
    position: sticky;
    width: 100%;
    .cart-summary-total {
        color: var(--textPrimary);
        font-size: var(--section-subtitle);
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .cart-summary-balance {
        color: var(--textSecondary);
        font-size: var(--section-text);
        display: flex;
        width: 100%;
        justify-content: space-between;

        .cart-summary-balance-wallet-image {
            width: var(--section-text);

            img {
                object-fit: contain;
            }
        }

        .cart-summary-wallet-value {
            color: var(--secondary);
            font-weight: 600;
            display: flex;
            gap: 5px;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    .cart-summary-right-align {
        text-align: right;
    }
    .jbox {
        .jbox-container {
            //border-radius: 30px 30px 0 0;
            overflow: visible;
            width: 35rem;
            .jbox-cta {
                button {
                    border-radius: 0 0 30px 30px;
                }
            }
        }
    }
}