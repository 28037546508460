.message-box {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: inherit;
  margin: 15px 0;

  @media (min-width: 900px) {
    width: 35rem;
  }

  .icon {
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .icon-placeholder {
    font-size: 1rem;
    margin-right: 15px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #ccc;
    background-color: #fff;
    color: #ccc;
    text-align: center;
  }

  .content {
    h1 {
      margin: 0;
      font-size: 0.9rem;
      font-weight: bold;
    }

    p {
      margin: 0;
      font-size: 0.8rem;
    }
  }
}
