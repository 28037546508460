@import '../../style/media-variables.scss';

.grid-container, .slider-container {
  font-family: var(--font-family);
  flex-direction: column;
  justify-content: start;
  display: flex;
  width: toRem(430px);
  height: auto;
  min-height: toRem(270px);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 400;
  line-height: toRem(27px);
  gap: toRem(20px);

  @include sm-max {
    gap: toRem(18px);
  }

  @include xxs-max {
    gap: toRem(14px);
  }

  .img-container img {
    max-height: toRem(60px);
    width: auto;
    object-fit: contain;

    @include sm-max {
      max-height: toRem(40px);
    }
  }

  .img-logo-container img {
    max-height: toRem(16px);
    width: auto;
    object-fit: contain;

    @include sm-max {
      max-height: toRem(12px);
    }

  }

  .description-text {
    font-size: toRem(18px);
    font-weight: 400;
    line-height: toRem(27px);
    max-width: toRem(414px);

    @include md-max {
      font-size: toRem(16px);
      line-height: toRem(20px);
      width: 100%;
      max-width: 345px;
    }

    @include xxs-max {
      max-width: toRem(272px);
      font-size: toRem(14px) !important;
    }

  }

  .cta-container {
    padding-top: toRem(30px);
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
  }

  .cta-link-text {
    font-size: toRem(20px);
    font-weight: 600;
    line-height: toRem(30px);
    font-family: var(--font-family);

    @include sm-max {
      font-size: toRem(16px);
      line-height: toRem(24px);
    }

  }

  .cta-link-text:hover {
    text-decoration: none;
    color: inherit;
    cursor: default;
    pointer-events: none;
  }

  .cta-container.start {
    justify-content: flex-start;
  }

  .cta-container.center {
    justify-content: center;
  }

  .cta-container.end {
    justify-content: flex-end;
  }

  .row-linkAndLogo {
    gap: 5px;
    font-weight: bold;

    .icon-style {
      font-size: toRem(25px);
      @include sm-max {
        font-size: toRem(21px);
      }
    }

  }

}

.slider-container {
  padding: 40px 35px;
  height: toRem(298px);
  width: toRem(545px);

  @include lg-max {
    width: auto;
    max-width: 545px;
  }

  @include md-max {
    width: auto;
    max-width: 420px;
    padding: 30px 20px;
  }

  @include xxs-max {
    width: auto;
    min-width: 260px;
  }
}


.title-text-slider {
  font-size: toRem(35px);
  font-weight: 600;
  line-height: 54px;
  width: toRem(414px);

  @include lg-max {
    font-size: toRem(32px);
    line-height: toRem(36px);
    width: 100%;
    max-width: 414px;
  }

  @include sm-max {
    font-size: toRem(30px) !important;
    line-height: 41px;
  }
}


.main-container {
  padding: 40px 35px;
  width: toRem(545px);

  @include lg-max {
    width: 100%;
    max-width: 545px;
    padding: 30px 20px;
  }

  @include sm-max {
    width: 100%;
    max-width: 414px;
    padding: 30px;
  }

  .title-text-grid {
    font-size: toRem(45px);
    font-weight: 600;
    line-height: 54px;
    width: toRem(414px);

    @include lg-max {
      font-size: toRem(32px);
      line-height: toRem(36px);
      width: 100%;
      max-width: 414px;
    }

    @include xxs-max {
      font-size: toRem(30px) !important;
    }
  }

}

.minor-container {
  padding: 35px;

  @include lg-max {
    width: 100%;
    max-width: 430px;
    padding: 30px 20px;
  }

  @include sm-max {
    width: 100%;
    max-width: 300px;
    padding: 30px;
  }

  .title-text-grid {
    font-size: toRem(40px);
    font-weight: 600;
    line-height: 54px;
    width: toRem(350px);

    @include lg-max() {
      font-size: toRem(30px);
      line-height: toRem(41px);
      width: 100%;
      max-width: 345px;
    }

    @include xxs-max() {
      font-size: toRem(28px);
    }

  }

  @include xxs-max{
    .description-text, .long-description-text {
      font-size: toRem(12px) !important;
      line-height: 21px;
    }
  }
}
