@import '../../../../style/media-variables';

.vas-card_public, .vas-card_private, .vas-card_public_main-card {
  width: 100%;
  max-width: 1120px;
  height: auto;
  padding: 3rem 5rem;
  display: flex;
  flex-direction: column;

  color: var(--textPrimary);
  font-family: var(--font-family);
  margin: 0 auto;
  border-radius: 20px;
  gap: toRem(40px);

  @include sm-max() {
    height: toRem(487px);
    padding: 1.56rem 1.62rem 1.69rem 1.62rem
  }

  @include md-max() {
    height: auto;
  }

  .vas-card-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    @include md-max() {
      gap: 1rem;
    }

    @include lg-min() {
      flex-direction: row;
      justify-content: space-between;
    }

    .left-side {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @include md-max() {
        width: 100%;
      }

      @include sm-max() {
        width: 100%;
      }

      .title-container-row {
        display: flex;
        gap: 0.5rem;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;

        .jcms-html-subtitle {
          width: auto;

          span {
            font-weight: 400;
            font-size: toRem(25px);

            @include xs-max() {
              font-size: toRem(13px);
            }
          }

        }

        .jcms-html-title {
          font-weight: 700;
          font-size: toRem(50px);
          margin: 0 !important;

          @include sm-max() {
            font-size: toRem(32px);
          }

          @include xs-max() {
            font-size: toRem(25px);
          }
        }
      }

      .main-title {
        .jcms-html-title span {
          @include xxs-max() {
            font-size: toRem(30px);
          }
        }

        .jcms-html-subtitle span {
          @include xxs-max() {
            font-size: toRem(16px);
          }
        }
      }


      .jcms-html-subtitle {
        span {
          font-size: toRem(40px);
          line-height: 53px;
          font-weight: 600;

          @include md-max() {
            font-size: toRem(26px);
            line-height: toRem(26px);
          }

          @include sm-max() {
            font-size: toRem(20px);
            line-height: toRem(20px);
          }
        }
      }

      .jcms-html-text {
        span {
          font-weight: 400;
          font-size: toRem(18px);
          line-height: 27px;

          @include md-max() {
            font-size: toRem(16px);
            line-height: toRem(24px);
          }

          @include sm-max() {
            font-size: toRem(14px);
            line-height: toRem(16px);
          }

        }
      }
    }

    .description-style span {
      padding-top: 1rem;
      @include sm-max() {
        font-size: toRem(26px) !important;
        line-height: toRem(39px) !important;
      }
    }

    .not-main-description {
      @include xxs-max() {
        max-width: toRem(190px);
      }
    }

    .long-description-style span {
      @include xxs-max() {
        font-size: toRem(14px);
        line-height: toRem(24px) !important;
      }
    }

    .right-side {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;


      @include md-max() {
        justify-content: center;
        padding: 40px 0 20px 0;
        width: 100%;
      }

      .side-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        .price {
          font-family: var(--font-family);
          font-size: toRem(30px);
          font-weight: 700;

          @include sm-max() {
            font-size: toRem(24px);
            line-height: toRem(25px);
          }
        }

        .cronDescription {
          font-family: var(--font-family);
          font-size: toRem(14px);
          font-weight: 400;
        }

        .vas-cta-purchased, .vas-cta-purchase {
          width: 100%;
          margin: 0.5rem 0 2rem 0;

          button {
            width: 100%;
            max-width: toRem(353px);
            height: toRem(48px);
            background: var(--primary);
            color: var(--textInvert);
            border: unset;

            span {
              font-family: var(--font-family);
              font-size: toRem(16px);
              font-weight: 600;
            }

            @include sm-max() {
              width: toRem(265px);
              height: toRem(44px);
              font-size: toRem(16px);
            }

            &:hover {
              background: none;
              border: unset;

              span {
                color: unset;
              }

            }
          }
        }

        .vas-cta-purchased {
          button {
            background: var(--disabled) !important;
            border: unset !important;

            span {
              font-family: var(--font-family);
              color: var(--textInvert);
            }

            &:hover {
              background: none;
              border: unset !important;

              span {
                color: var(--textInvert);
              }
            }
          }
        }

        .vas-registration-cta {
          margin-top: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;

          button {
            width: toRem(248px);
            height: toRem(44px);
            @include sm-max() {
              width: toRem(200px);
              height: toRem(44px);
            }

            span {
              font-family: var(--font-family);
              font-weight: 600;
              font-size: toRem(16px);
              line-height: toRem(24px);
            }

            &:hover {
              background: var(--primary);
            }
          }
        }
      }
    }
  }

  .footer-title {
    .jcms-html-title span {
      @include xxs-max() {
        font-size: toRem(27px) !important;
      }
    }
  }

  .vas-card-body {
    .slick-slider {
      @include sm-max() {
        .slick-list {
          height: auto !important;

          .slick-track {
            height: auto !important;
          }
        }
      }

      .slick-slide {
        width: auto !important;
        height: auto !important;
        @include sm-max() {
          width: auto !important;
          height: auto !important;
        }
      }
    }
  }

  .vas-card-footer {
    width: auto;
    max-width: 387px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    gap: 0.5rem;
    margin-top: 1rem;

    @include sm-max() {
      margin: 0;
      align-self: center;
      padding: 20px 0;
    }

    .jcms-html-title {
      margin: 0 !important;

      span {
        font-weight: 600;
        font-size: toRem(40px);

        @include sm-max() {
          font-size: toRem(28px);
        }
      }

      @include sm-max() {
        font-size: toRem(14px);
      }
    }

    .title-container-col {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      .jcms-html-subtitle {
        width: auto;

        span {
          font-weight: 400;
          font-size: toRem(14px);
        }
      }

      .jcms-html-title {
        margin: 0 !important;

        span {
          font-weight: 700;
          font-size: toRem(30px);
        }

        @include sm-max() {
          span {
            font-size: toRem(20px);
          }
        }
      }
    }

    .btn-container {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      width: auto;

      .vas-card-more-info {
        position: absolute;
        bottom: 1.3125rem;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        @include sm-max() {
          position: unset;
          margin-top: toRem(16px);

        }
        @include md-max() {
          margin-top: toRem(16px);
          position: unset;
        }

        button {
          width: toRem(205px);
          height: toRem(48px);
          background: var(--white) !important;
          @include sm-max() {
            width: toRem(190px);
            height: toRem(44px);
            font-size: toRem(16px);
          }

          span {
            font-family: var(--font-family);
            color: var(--textPrimary) !important;
            font-size: toRem(20px);
            font-weight: 600;
          }
        }
      }

      .vas-registration-cta {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          width: toRem(248px);
          height: toRem(44px);
          @include sm-max() {
            width: toRem(200px);
            height: toRem(44px);
          }

          span {
            font-family: var(--font-family);
            font-weight: 600;
            font-size: toRem(16px);
            line-height: toRem(24px);
          }

          &:hover {
            background: var(--primary);
          }
        }
      }

    }

  }

  .minor-title {
    .jcms-html-title {
      span {
        font-size: toRem(35px);
      }
    }
  }
}

.noPadding {
  padding: 3rem 0;
}

.vas-card_public {
  padding: 3rem 1.5rem;

  @include lg-min() {
    padding: 5rem 8rem;
  }
}

.vas-card_public_main-card {
  padding: 3rem 0;

  @include sm-max() {
    padding: 3rem 0 0 0;
  }
}

.vas-card_private {
  max-width: 100%;
  min-height: 796px;
  border-radius: unset;
  padding: 4rem 0.625rem 1.15625rem 5.875rem;


  @include xs-max() {
    padding: 3.438rem 1.5rem 4.625rem 1.5rem;
  }

  .vas-card-header {

    .right-side {
      padding-right: 7rem;
      @include xs-max() {
        padding-right: 0;
      }
    }
  }
}
