@import './../../../../style/media-variables.scss';

.comparison-tab-container {
  padding: 0;
}

.table-container {
  font-family: var(--font-family);
  display: grid;
  background-color: #f5f5f5;
  border-radius: toRem(20px);
  padding: 60px 100px 100px 100px;
  width: 100%;
  height: auto;
  grid-template-columns: 1fr repeat(var(--num-cols), minmax(200px, 1fr)) !important;

  @include sm-max() {
    padding: 30px 0;
    grid-template-columns: 1fr repeat(2, 50%) !important;
    row-gap: toRem(25px);
  }
}

.tab-container-with-cta {
  @extend .table-container;
  @include sm-max() {
    padding: 30px 0 0 0;
    min-height: toRem(650px);
  }
}

.comparison-tab-title {
  padding: 20px 0;
  font-size: toRem(40px);
  font-weight: bold;

  @include xs-max() {
    font-size: toRem(26px);
  }
}

.table-header, .table-row, .table-footer {
  display: contents;
  font-size: 18px;
}

.table-header {
  .table-cell {
    @include sm-min() {
      margin-bottom: toRem(20px);
    }
  }
}

.t-center {
  text-align: center;
}

.border-none {
  border: none !important;
}

.t-left {
  text-align: left;
}

.t-right {
  text-align: right;
}

.table-cell {
  display: flex;
  align-items: center;
  padding: 13px 0;
  font-size: toRem(20px);
  line-height: toRem(30px);
  border-top: 1px solid #b5b3b3;

  @include xs-max() {
    padding: 0;
    font-size: toRem(12px);
    line-height: toRem(18px);
  }
}

.table-header-text {
  font-weight: bold;
  font-size: toRem(30px);
  @include sm-max() {
    font-size: toRem(20px);
  }
}

.table-small-text {
  font-size: toRem(14px);
  @include xxs-max{
    font-size: toRem(10px);
  }
}

.table-cell-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table-cell-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include sm-max() {
    align-items: center !important;
  }
}

.item-alignment-center {
  display: flex;
  justify-content: center;
}

.item-alignment-value-cell {
  display: flex;
  justify-content: end;

  @include sm-max() {
    justify-content: center !important;
  }
}

.icon-check {
  color: #0ba322;
  font-size: 24px;

  @include xs-max() {
    max-width: toRem(18px);
  }

}

.item-width {
  min-width: toRem(64px);
  position: relative;

  @include xs-max() {
    max-width: toRem(105px);
  }

  button {
    width: auto;
    min-width: 153px;
    height: auto;
    min-height: 44px;

    @include xs-max() {
      min-width: 75px !important;
      min-height: 22px;
    }

    @include xxs-max() {
      min-width: 75px !important;
      min-height: 22px;
    }

    span {
      @include xs-max() {
        font-size: 10px !important;
      }
    }
  }
}

.pt-10 {
  padding-top: unset;
  .jcms-cta button.jcms-cta-type-button.jcms-cta-color-primary {
    margin-top: 20px;
    &:hover {
      background: var(--primary);
    }
  }
}

.custom-position {
  position: relative;
  right: -12%;

  @include sm-max() {
    position: static;
    right: auto;
  }
}