@import '../../../../style/media-variables';

.card-subscription-purchased {
  background: var(--background-silver);
  height: auto;
  min-height: toRem(469px);
  border-radius: toRem(20px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: toRem(1376px);
  display: flex;
  flex-direction: column;
  padding: 2.94rem 2.06rem 2.62rem 2.56rem;

  @include md-max() {
    padding: 1.5rem;
    flex-direction: column;
  }

  .activation-date {
    width: auto;
    max-width: toRem(236px);
    height: auto;
    min-height: toRem(35px);
    border-radius: toRem(20px);
    align-content: center;
    text-align: center;
    font-size: toRem(15px);
    color: var(--textPrimary);
    margin-bottom: 1.2rem;
  }

  .card-subscription-purchased-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    @include sm-max() {
      gap: 0;
      flex-direction: column;
    }

    @include md-max() {
      gap: 0;
      flex-direction: column;
    }

    .card-leftSide {
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      @include md-max() {
        width: 100%;
        padding: 1.5rem;
      }

      .title-container{
        display: flex;
        flex-direction: column;

        .jcms-html-text {
          text-transform: uppercase;
          color: var(--textPrimary);
          font-size: toRem(14px);
          font-weight: 400;
          font-family: var(--font-family);
          @include md-max() {
            font-size: toRem(12px);
          }
        }

        .jcms-html-title{
          align-items: flex-start;
          text-align: start;
          font-size: toRem(30px);
          font-weight: bold;
          color: var(--textPrimary);
          font-family: var(--font-family);
          margin: 0;
          @include md-max() {
            font-size: toRem(20px);
          }
        }
      }

      .jcms-html-subtitle {
        align-items: flex-start;
        text-align: start;
        color: var(--textPrimary);
        font-size: toRem(18px);
        font-weight: 400;
        @include md-max() {
          font-size: toRem(14px);
        }
      }

      .btn-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 1rem;
        margin-top: 1rem;

        @include md-max() {
          display: flex;
          justify-content: center;
          margin-top: 2rem;
          align-items: center;
        }

        @include sm-max() {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 2rem;
        }


        .btn-more-info {
          button {
            width: auto;
            min-width: toRem(205px);
            height: toRem(48px);
            background: var(--white);
            @include md-max() {
              min-width: toRem(190px);
              height: toRem(44px);
            }

            span {
              font-family: var(--font-family);
              color: var(--textPrimary) !important;
              font-size: toRem(20px);
              font-weight: 600;
            }

            &:hover {
              background: var(--white);

              span {
                color: var(--textPrimary) !important;
              }
            }
          }
        }

        .btn-catalog {
          button {
            width: auto;
            min-width: toRem(205px);
            height: toRem(44px);
            background: var(--primary);
            border: unset;

            span {
              font-family: var(--font-family);
              color: var(--textInvert);
              font-size: toRem(16px);
              font-weight: 600;
            }

            @include md-max() {
              min-width: toRem(190px);
              height: toRem(44px);
            }

            &:hover {
              background: var(--primary);

              span {
                color: var(--textInvert);
              }
            }
          }
        }
      }

    }

    .card-rightSide {
      width: 60%;
      height: 100%;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;

      @include md-max() {
        width: 100%;
      }

      h1.jcms-html-title {
        margin-bottom: 1rem;
        span {
          align-items: flex-start;
          text-align: start;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: toRem(22px);
          color: var(--textPrimary);
          font-family: var(--font-family);
          margin: 0;
          @include md-max() {
            font-size: toRem(20px);
          }
        }

      }

      .card-catalog {
        max-width: 100%;

        .jcard-container {
          border: 1px solid #DFDFDF;

          .jcard-header {
            height: toRem(150pxpx);

            @include sm-max() {
              height: 100%;
              max-width: toRem(121px);
            }

            img.jcard-cover-image {
              object-fit: cover;
            }
          }

          .jcard-content {
            .card-catalog-content {
              justify-content: start;
            }
          }

        }

      }
    }
  }
}